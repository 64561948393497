import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchedocumentcount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchedocumentcountmodule(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslist/'+queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
